<script>
export default {
  render() {
    return this.$scopedSlots.default({
      showing: this.showing,
      toggle: this.toggle,
      check: this.check,
      failedCandidates: this.failedCandidates,
      error: this.error,
      totalSendCount: this.totalSendCount
    })
  },

  data: () => ({
    showing: false,
    failedCandidates: [],
    error: '',
    totalSendCount: 0
  }),

  methods: {
    toggle() {
      this.showing = !this.showing
    },

    check(res, totalSendCount) {
      if (!res.failedCandidates.length) return this.$emit('checked', res)
      this.failedCandidates = res.failedCandidates
      this.error = res.error
      this.totalSendCount = totalSendCount
      this.toggle()
      this.$emit('checked', res)
    }
  }
}
</script>
